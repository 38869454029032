import React from "react";

import { StyledZone, StyledZoneRow, StyledPrices } from "./Zones.styled";

const Prices = () => {
  return (
    <StyledZone className="prices">
      <StyledZoneRow>
        <div className="textBox">
          <h3>Cennik</h3>

          <StyledPrices>
            <div>
              <p>składka miesięczna</p> <h4>250 pln</h4> obejmuje treningi w
              Warszawskim Centrum Aikido, w dowolnej lokalizacji
            </div>
            <div>
              <p>składka za 3 miesiące</p> <h4>690 pln</h4> oszczędzasz 60 pln
            </div>
            <div>
              <p>składka za 10 miesięcy</p> <h4>2200 pln</h4> oszczędzasz 300
              pln
            </div>
            <div>
              <p>składka rodzinna</p>{" "}
              <p>
                {" "}
                <h4> 230 pln/os</h4> oszczędzasz 20 pln/msc za każdą osobę
              </p>
            </div>
          </StyledPrices>
          <span>* zniżki nie łączą się</span>
        </div>
      </StyledZoneRow>
    </StyledZone>
  );
};

export default Prices;
